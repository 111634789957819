<template>
<div id="app"> 

                <CRow>
                  <CCol col="12" class="text-left">                   
                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                  </CCol> 
                </CRow> 

                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Upload Image</label>
                  </b-col>   
                  <b-col sm="9"> 
                      <input type="file" @change="onFileChangedImg">
                      <b-button-group>    
                      <CButton @click="onUploadImg" :disabled="!isDisabledbtnImg" color="success">Upload</CButton>
                      <CButton  v-on:click="actionShowImg()" color="warning">Show</CButton>
                      <CButton  v-on:click="actionDownloadImg()" color="primary">Download</CButton>                      
                      </b-button-group>   
                  </b-col>                  
                </b-row><br/>

                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Upload Logo</label>
                  </b-col>   
                  <b-col sm="9"> 
                      <input type="file" @change="onFileChangedLogo"> 
                      <b-button-group>   
                        <CButton @click="onUploadLogo" :disabled="!isDisabledbtnLogo" color="success">Upload</CButton>
                        <CButton  v-on:click="actionShowLogo()" color="warning">Show</CButton>
                        <CButton  v-on:click="actionDownloadLogo()" color="primary">Download</CButton>                        
                      </b-button-group>   
                  </b-col>                  
                </b-row><br/>

                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Preview {{imgType}}</label>

                  </b-col>   
                  <b-col sm="9">
                      <b-img @error="onImageLoadFailure($event)" v-bind="imgProps" :src="'data:image/png;base64,'+ imgMerchant " alt=""></b-img>
                  </b-col>                  
                </b-row><br/>

                 <b-row>
                  <b-col sm="3">
                  </b-col>   
                  <b-col sm="9"> 
                      <CButton  v-on:click="actionRefresh()" color="primary">Refresh Preview</CButton>
                  </b-col>                  
                </b-row><br/>

                


</div>
</template>

<script>
 import AuthService from '@/api/AuthService.js'; 
 import MerchantService from '@/api/MerchantService.js';
 
  export default { 
    name: 'limits',
    components: { 
    },     
    data() {
      return { 
          imgType : '',
          imgMerchant:'',
          imageKey:'',
          logoKey:'',
          defaultImageKey:'',
          selectedFile: null, 
          isDisabledbtnImg:true,
          isDisabledbtnLogo:true,
          imgProps: {  width: 200, height: 200  },  
          seen:false,
          msg :'',
          color:'',
          count:1
        };      
    },
    created () {        
        //this.getConfigList(); 
         this.getDetailMerchant(this.$route.params.id);           
    },
    methods: {

      actionRefresh(){
          this.getDetailMerchant(this.$route.params.id); 
      },

      onImageLoadFailure (event) {
            event.target.src = 'img/default.png'     
      },

      actionShowImg(){
         var key      = (this.imageKey !== 'undefined') ? this.imageKey : this.defaultImageKey; 
         var is_exist = (this.imageKey !== 'undefined') ? true : false;
         var show     = "true";
         if(!is_exist){ this.downloadDefaultImg(key,show); } else { this.downloadResource(key,"image",show); }
      },

      actionDownloadImg(){
        var key      = (this.imageKey !== 'undefined') ? this.imageKey : this.defaultImageKey; 
        var is_exist = (this.imageKey !== 'undefined') ? true : false;
        if(!is_exist){ this.downloadDefaultImg(key); } else { this.downloadResource(key,"image"); }        
      },

      actionShowLogo(){
          var show     = "true";
          this.downloadResource(this.logoKey,"logo",show);
      },

      actionDownloadLogo(){ 
         var show     = "false";        
         this.downloadResource(this.logoKey,"logo", show);
      },

      onFileChangedImg(){
         this.selectedFile = event.target.files[0];
      },

      async onUploadImg(){

          const formData = new FormData()
          formData.append('resource', this.selectedFile, this.selectedFile.name);

          try {
              var merchantId = this.$route.params.id;            
              const resp    = await MerchantService.uploadImageMerchant(merchantId, formData);   //console.log(resp);

              if(resp.message==="Success"){                  
                  this.msg   = "Upload Image Merchant Success!"; 
                  this.color = 'success'; this.seen  = true;              
              } else {
                  this.msg   =  !resp.data.message ? "Upload Image Merchant Failed!" : resp.data.message; 
                  this.color = 'danger'; this.seen  = true;              
              }  

            setTimeout( () =>  this.seen=false , 5000); 

          } catch (error) { console.log(error); }

      }, 

      onFileChangedLogo(){
          this.selectedFile = event.target.files[0];
      },   

      async onUploadLogo(){

          const formData = new FormData()
          formData.append('resource', this.selectedFile, this.selectedFile.name);

          try {
              var merchantId = this.$route.params.id;            
              const resp    = await MerchantService.uploadLogoMerchant(merchantId, formData);   //console.log(resp);

              if(resp.message==="Success"){                  
                  this.msg   = "Upload Logo Merchant Success!"; 
                  this.color = 'success'; this.seen  = true;              
              } else {
                  this.msg   =  !resp.data.message ? "Upload Logo Merchant Failed!" : resp.data.message; 
                  this.color = 'danger'; this.seen  = true;              
              }  

            setTimeout( () =>  this.seen=false , 5000); 

          } catch (error) { console.log(error); }

      },

      downloadResource: function(key,type,show) { 
          MerchantService.downloadResource(key,type).then(resp => {  //console.log(resp);

              if(show==="true"){ 
                  this.imgMerchant = resp;  this.imgType = type;
              } else {
                  var ImageBase64 = resp;
                  var a = document.createElement("a"); 
                  a.href = "data:image/png; base64," + ImageBase64; 
                  a.download = key; a.click();
              }                  
           
          }, error => {
            console.log('error');    
          });
      }, 

      downloadDefaultImg: function(key,show) { 
          MerchantService.downloadDefaultImg(key).then(resp => {  //console.log(resp);

              if(show==="true"){ 
                  this.imgMerchant = resp;  this.imgType = type; 
              } else {
                  var ImageBase64 = resp;
                  var a = document.createElement("a"); 
                  a.href = "data:image/png; base64," + ImageBase64; 
                  a.download = key; a.click();
              }                  
           
          }, error => {
            console.log('error');    
          });
      },        

      getDetailMerchant: function(id) { 
          MerchantService.getDetailMerchant(id).then(resp => {  //console.log(resp);

              this.imageKey = resp.imageKey ;
              this.logoKey  = resp.logoKey;
              this.defaultImageKey = resp.defaultImageKey;
           
          }, error => {
            console.log('error');    
          });
      },     

      getConfigList: function() { 
          AuthService.getConfigList().then(resp => {
            var currSrc=[]; 
            var arrcurrency = resp.currency; 

            for (var key in CurrDialCode){
              var dialcode = CurrDialCode[key];
              for (const item of arrcurrency){
                if(key==item){
                   currSrc.push({ value: dialcode , text: item }); 
                }
              }
            }
            currSrc.push({ value: "*" , text: "*" }); 
            this.optCurrency = currSrc;
            
           }, error => {
            console.log('error');    
          });
      },     
      
    }
  }
</script>





