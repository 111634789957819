<template>
<div id="app">   
  <CRow>
    <CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <CRow>
            <CCol col="6" class="text-left"><h4>Detail Merchant</h4></CCol>
            <CCol col="6" class="text-right">            

            </CCol> 
            </CRow>     
          </CCardHeader>
          <CCardBody>               
            <CForm>
              <CRow>
                <CCol col="12" class="text-left">                   
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol> 
              </CRow>

                  
           <b-tabs content-class="mt-3">
              <b-tab title="General">
                   
                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small">Merchant Id</label>
                  </b-col>   
                  <b-col sm="10">                     
                      <CInput :disabled="!isDisabledmchId" v-model="merchantId" />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small" >Display Name</label>
                  </b-col>   
                  <b-col sm="4">                     
                      <CInput :disabled="!isDisableddisName" v-model="displayName"/>
                  </b-col>
                  <b-col sm="2">                    
                     <label for="input-small">User Name</label>
                  </b-col>   
                  <b-col sm="4"> 
                      <CInput :disabled="!isDisableduName" v-model="userName" />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small" >First Name</label>
                  </b-col>   
                  <b-col sm="4">                     
                      <CInput :disabled="!isDisabledfName" v-model="firstName"/>
                  </b-col>
                  <b-col sm="2">                    
                     <label for="input-small">Last Name </label>
                  </b-col>   
                  <b-col sm="4"> 
                      <CInput :disabled="!isDisabledlName" v-model="lastName" /> 
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small">Branch Name</label>
                  </b-col>   
                  <b-col sm="10">                     
                      <CInput :disabled="!isDisabledbrN" v-model="branchName" />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small">Email Address</label>
                  </b-col>   
                  <b-col sm="10">                     
                      <CInput :disabled="!isDisabledeml" v-model="emailAddress" />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small">Mobile Number</label>
                  </b-col>   
                  <b-col sm="3"> 
                      <b-form-select :disabled="!isDisabledcCode" v-model="countryCode" :options="optcountryCode"></b-form-select>
                  </b-col>
                   <b-col sm="7">                     
                      <CInput :disabled="!isDisabledmnum" v-model="mobileNumber" />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small">Address</label>
                  </b-col>   
                  <b-col sm="10">
                      <CTextarea :disabled="!isDisabledaddrss" v-model="address" placeholder="Address" rows="3"/>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small" >Status</label>
                  </b-col>   
                  <b-col sm="10"> 
                      <b-form-select :disabled="!isDisabledsts" v-model="merchantStatus" :options="optStatus"></b-form-select>
                  </b-col>                  
                </b-row><br/>

              
                <CRow>
                <CCol col="6" class="text-left"></CCol>
                <CCol col="6" class="text-right">                  
                    <b-button-group>                
                       <b-button variant="warning" v-on:click="actionEdit()" v-if="seenBtnWarn">Edit</b-button>
                       <b-button v-on:click="actionCancel()" v-if="seenBtnCcel">Cancel</b-button>
                       <b-button variant="success" v-on:click="actionUpdate()" v-if="seenBtnUpdate">Update</b-button>
                    </b-button-group>
                </CCol> 
                </CRow>
              </b-tab>
              <b-tab title="Resources" >
                 <resources />
              </b-tab> 
              <b-tab title="Wallet" >
                <wallet />
              </b-tab>                             
           </b-tabs> 
            
           </CForm>
          </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
 import AuthService from '@/api/AuthService.js'; 
 import MerchantService from '@/api/MerchantService.js';
 import VueTimepicker from 'vue2-timepicker';
 import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
 import Multiselect from 'vue-multiselect';
 import moment from 'moment';
 import CurrDialCode from '@/assets/currwithdialcode.json'; 
 import Wallet from './wallet';
 import Resources from './resources';
 
  export default { 
    name: 'limits',
    components: {      
      VueTypeaheadBootstrap, 
      Multiselect,
      VueTimepicker,
      'wallet': Wallet,
      'resources': Resources,
    },     
    data() { var date = new Date();
      return {          
          recordId:'',
          merchantId:'',
          displayName:'', 
          userName:'', 
          firstName:'',
          lastName:'',
          branchName:'',
          emailAddress:'',
          countryCode:'',
          optcountryCode:[],
          mobileNumber:'',
          address:'',
          merchantStatus:'',
          optStatus:[],
          isDisabledeml:false,
          isDisabledmchId:false,
          isDisableddisName:false,  
          isDisableduName:false,
          isDisabledcCode:false, 
          isDisabledmnum:false, 
          isDisabledfName:false,
          isDisabledlName:false, 
          isDisabledaddrss:false, 
          isDisabledsts:false, 
          isDisabledbrN:false, 
          seenBtnCcel: false,
          seenBtnWarn: true,
          seenBtnUpdate:false,          
          seen:false,
          msg :'',
          color:''
        };      
    },
    created () {      
      this.getMerchantById(this.$route.params.id);
      this.getDetailMerchant(this.$route.params.id);
      this.getConfigList();
    },
    mounted() {
      
    },
    methods: {       

      actionUpdate(){         

        let params = {}; let subparams = {}; 
        params['acquirerRefId'] = "";     
        params['address'] = this.address;
        params['branchName'] = this.branchName;
        params['displayName'] = this.displayName;
          //params['userName'] = this.userName;
        params['firstName'] = this.firstName;
        params['lastName'] = this.lastName;
        params['emailAddress'] = this.emailAddress;        
        subparams['countryCode'] = this.countryCode;
        subparams['number'] = this.mobileNumber;
        params['mobileNumber'] = subparams;        
        params['merchantStatus'] = this.merchantStatus; console.log(params);  

        var merchantId = this.recordId;  //console.log(merchantId);
        var fields = ["branchName","displayName","emailAddress"];
        var validate = [];
        for (var key in fields) { 
            var keyName = fields[key];
            var status  = !params[keyName] ? false :true;
            validate.push(status);
        }

        if(validate.includes(false)){
          this.msg   = "Not Complete Data, Please Check Form"; 
          this.color = 'warning'; this.seen  = true;  
          setTimeout( () =>  this.seen=false , 5000); 
        } else {
           this.updateMerchant(merchantId,params); 
        }

      }, 

      actionEdit(){          

          this.seenBtnCcel=true;
          this.seenBtnWarn=false;
          this.seenBtnUpdate=true;
          this.isDisabledeml=true;
          //this.isDisabledmchId=true;
          this.isDisableddisName=true;  
          //this.isDisableduName=true;
          this.isDisabledcCode=true;
          this.isDisabledmnum=true;
          this.isDisabledfName=true;
          this.isDisabledlName=true;
          this.isDisabledaddrss=true;
          this.isDisabledsts=true; 
          this.isDisabledbrN=true;

          //this.isDisabledbtnImg=true;
          //this.isDisabledbtnLogo=true;
          
      },

      actionCancel(){

          this.seenBtnCcel=false;
          this.seenBtnWarn=true;
          this.seenBtnUpdate=false;
          this.isDisabledeml=false;
          //this.isDisabledmchId=false;
          this.isDisableddisName=false;  
          this.isDisableduName=false;
          this.isDisabledcCode=false,
          this.isDisabledmnum=false;
          this.isDisabledfName=false;
          this.isDisabledlName=false;
          this.isDisabledaddrss=false;
          this.isDisabledsts=false;
          this.isDisabledbrN=false;

          //this.isDisabledbtnImg=false;
          //this.isDisabledbtnLogo=false;
      }, 

      updateMerchant: function(id,params) {
        MerchantService.updateMerchant(id,params).then(resp => { //console.log(resp);

            if(resp.message==="Success"){                  
              this.msg   = "Update Merchant Success!"; 
              this.color = 'success'; this.seen  = true;   this.actionCancel();           
            } else {
              this.msg   =  !resp.data.message ? "Update Merchant Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            }  

            setTimeout( () =>  this.seen=false , 5000);      
        
        }, error => {
          this.loading = false;
        });
      },    

      getMerchantById: function(id) { 
          MerchantService.getMerchantById(id).then(resp => {  //console.log(resp);

            this.recordId = resp.id;
            this.merchantId = this.$route.params.id;
            this.displayName = resp.displayName; 
            this.userName = resp.userName;
            this.firstName = resp.firstName;
            this.lastName  = resp.lastName;
            this.branchName = resp.branchName;
            this.emailAddress = resp.emailAddress; 
            this.countryCode = resp.mobileNumber.countryCode;
            this.mobileNumber = resp.mobileNumber.number; 
            this.merchantStatus = resp.merchantStatus; 
            this.address = resp.address;

          }, error => {
            console.log('error');    
          });
      },   

      getDetailMerchant: function(id) { 
          MerchantService.getDetailMerchant(id).then(resp => {  //console.log(resp);

              this.imageKey = resp.imageKey ;
              this.logoKey  = resp.logoKey;
              this.defaultImageKey = resp.defaultImageKey;
           
          }, error => {
            console.log('error');    
          });
      },    

      getConfigList: function() { 
          AuthService.getConfigList().then(resp => { //console.log(resp);

            var exeptSts= ["BLOCKED","CONSUMED","DELETED"];
            var recSts = resp.recordStatus; var recStsSrc=[];
            for (var key in recSts) {
                var recStsName = recSts[key]; 
                if(!exeptSts.includes(recStsName)){  
                recStsSrc.push({ value: recStsName , text: recStsName });
                }
            }
            this.optStatus = recStsSrc;

            var countryCodeSrc = [];
            for (var key in CurrDialCode){
                var dialcode = CurrDialCode[key];              
                countryCodeSrc.push({ value: dialcode , text: dialcode }); 
            }

            this.optcountryCode = countryCodeSrc;
        
            var currSrc=[]; var temp = []; var temp2 = [];
            var arrcurrency = resp.currency;
            for (const item of arrcurrency){ temp.push(item); }
            temp.sort(); 
            for (var key in temp) {
                var curr = temp[key];  
                currSrc.push({ value: curr , text: curr });
            }
            this.optCurrency = currSrc;

            var bentSrc=[]; var temp2 = [];
            var bentype = resp.promoBenefitTypes;
            for (var key2 in bentype) {
                var ben = bentype[key2];  
                bentSrc.push({ value: ben , text: ben });
            }
            this.optbenType = bentSrc;

            var promoSrc=[]; var temp3 = [];
            var promoType = resp.promoTypes;
            for (var key3 in promoType) {
                var promo = promoType[key3];  
                promoSrc.push({ value: promo , text: promo });
            }
            this.optPromoType = promoSrc;

           }, error => {
            console.log('error');    
          });
      }, 

    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>